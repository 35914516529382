import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'footer': {
        'contactUs': [
          'Contact us',
          'Kontakt'
        ],
        'contactUsLink': [
          'http://new.siemens.com/global/en/general/contact.html',
          'https://new.siemens.com/global/de/general/kontakt.html'
        ],
        'cookieNotice': [
          'Cookie Notice',
          'Cookie-Hinweise'
        ],
        'cookieNoticeLink': [
          'https://new.siemens.com/global/en/general/cookie-notice.html',
          'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
        ],
        'corporateInformation': [
          'Corporate Information',
          'Impressum'
        ],
        'corporateInformationLink': [
          'https://new.siemens.com/global/en/general/legal.html',
          'https://new.siemens.com/global/de/general/legal.html'
        ],
        'digitalId': [
          'Digital ID',
          'Digitales Zertifikat'
        ],
        'digitalIdLink': [
          'https://new.siemens.com/global/en/general/digital-id.html',
          'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
        ],
        'privacyNotice': [
          'Privacy Notice',
          'Datenschutz'
        ],
        'privacyNoticeLink': [
          'https://new.siemens.com/global/en/general/privacy-notice.html',
          'https://new.siemens.com/global/de/general/datenschutz.html'
        ],
        'siemens': [
          '© Siemens, 1996 - {date}',
          '© Siemens, 1996 - {date}'
        ],
        'siemensWebsite': [
          'siemens.com Global Website',
          'siemens.com Germany'
        ],
        'siemensWebsiteLink': [
          'https://new.siemens.com/global/en.html',
          'https://new.siemens.com/global/de.html'
        ],
        'termsOfUse': [
          'Terms of use',
          'Nutzungsbedingungen'
        ],
        'termsOfUseLink': [
          'https://new.siemens.com/global/en/general/terms-of-use.html',
          'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
        ]
      },
      'header': {
        'anonymus': [
          'Anonymus',
          'Anonymus'
        ],
        'language': [
          'Language',
          'Sprache'
        ],
        'languages': {
          'de': [
            'Deutsch',
            'Deutsch'
          ],
          'en': [
            'English',
            'English'
          ]
        },
        'login': [
          'Log In',
          'Log In'
        ],
        'logout': [
          'Log Out',
          'Log Out'
        ],
        'nextProject': [
          'Next Project',
          'Next Project'
        ],
        'previousProject': [
          'Previous Project',
          'Previous Project'
        ],
        'reportProblem': [
          'Report a problem',
          'Ein Problem melden'
        ],
        'searchProject': [
          'Search Project',
          'Search Project'
        ],
        'skipToMainContent': [
          'Skip to main content',
          'Skip to main content'
        ],
        'support': [
          'Support',
          'Unterstützung'
        ],
        'switchProject': [
          'Switch Project',
          'Switch Project'
        ]
      },
      'helperPages': {
        'authError': [
          'Authentication error',
          'Authentication error'
        ],
        'authErrorMessage': [
          'An error occured during authentication. Please try again.',
          'An error occured during authentication. Please try again.'
        ],
        'authProgress': [
          'Authentication in progress',
          'Authentication in progress'
        ],
        'authProgressMessage': [
          'We will redirect you to the login page',
          'We will redirect you to the login page'
        ],
        'authSuccess': [
          'Authentication successful',
          'Authentication successful'
        ],
        'callbackError': [
          'An error occured',
          'An error occured'
        ],
        'callbackErrorMessage': [
          'Please try again.',
          'Please try again.'
        ],
        'error': [
          'Error',
          'Error'
        ],
        'errorOccured': [
          'An error occured while processing your request',
          'An error occured while processing your request'
        ],
        'homeLoginMessage': [
          'Please login to continue',
          'Please login to continue'
        ],
        'homeMessage': [
          'Welcome to POWERTRAIN!',
          'Welcome to POWERTRAIN!'
        ],
        'sessionLost': [
          'Session expired',
          'Session expired'
        ],
        'sessionLostMessage': [
          'Your session expired. Please reauthorize.',
          'Your session expired. Please reauthorize.'
        ],
        'unauthorizedMessage': [
          'Unauthorized access',
          'Unauthorized access'
        ]
      },
      'layout': {
        'layout_costyearscountries_analysis': [
          'Cost Years Countries Analysis',
          'Cost Years Countries Analysis'
        ],
        'layout_country_evaluation': [
          'Country Evaluation',
          'Country Evaluation'
        ],
        'layout_orders': [
          'Orders',
          'Orders'
        ],
        'layout_project_controlling': [
          'Project Controlling',
          'Project Controlling'
        ],
        'layout_turnover': [
          'Turnover',
          'Turnover'
        ]
      },
      'main': {
        'actual': [
          'Actual',
          'Actual'
        ],
        'actualPlanCosts': [
          'Actual/Plan costs',
          'Actual/Plan costs'
        ],
        'approve': [
          'Approve',
          'Approve'
        ],
        'approvalNo': [
          'Approval No.',
          'Approval No.'
        ],
        'assigned': [
          'Assigned',
          'Assigned'
          ],
        'attachments': [
          'Attachments',
          'Attachments'
         ],
        'backgroundSaveMessage': [
          'You have just closed a project. Would you like to save it?',
          'You have just closed a project. Would you like to save it?'
        ],
        'baseOperatingSystem': [
          'Base Operating System',
          'Base Operating System'
        ],
        'cancel': [
          'Cancel',
          'Cancel'
        ],
        'cancelFiscalYearClosing': [
            'Cancel fiscal year closing',
            'Cancel fiscal year closing'
        ],
        'charged': [
          'Charged',
          'Charged'
        ],
        'city': [
          'City',
          'City'
        ],
        'class': [
          'Class',
          'Class'
        ],
        'close': [
            'Close',
            'Close'
        ],
        'closeFiscalYear': [
            'Close fiscal year',
            'Close fiscal year'
        ],
        'closed': [
          'Closed',
          'Closed'
        ],
        'contactExample': [
          'Contact & Supplier (e.g. \'Mr.Dummy - dummy@siemens.com - supplier number\')',
          'Contact & Supplier (e.g. \'Mr.Dummy - dummy@siemens.com - supplier number\')'
        ],
        'contactType': [
          'Contact type',
          'Contact type'
        ],
        'comment': [
          'Comment',
          'Comment'
        ],
        'competitor': [
          'Competitor',
          'Competitor'
        ],
        'corpGroup': [
          'Corp. group',
          'Corp. group'
        ],
        'corporationGroup': [
          'Corporation Group',
          'Corporation Group'
        ],
        'cost': [
          'Cost',
          'Cost'
        ],
        'costCalculated': [
          'Cost Calculated',
          'Cost Calculated'
        ],
        'costCharged': [
          'Cost Charged',
          'Cost Charged'
        ],
        'costPlan': [
          'Cost Plan',
          'Cost Plan'
        ],
        'costType': [
          'Cost Type',
          'Cost Type'
        ],
        'costsByYears': [
          'Costs By Years',
          'Costs By Years'
        ],
        'costYears': [
          'Cost Years',
          'Cost Years'
        ],
        'costYearsCountry': [
          'Cost Years Country',
          'Cost Years Country'
        ],
        'costsCalculated': [
          'Costs calculated',
          'Costs calculated'
        ],
        'costsPlan': [
          'Costs Plan',
          'Costs Plan'
        ],
        'costsPlanOfSupportedVolume': [
          'Costs plan of supported volume',
          'Costs plan of supported volume'
        ],
        'costsCalculatedOfSupportedVolume': [
          'Costs calculated of supported volume',
          'Costs calculated of supported volume'
        ],
        'country': [
          'Country',
          'Country'
        ],
        'countryDecisionMaker': [
          'Country decision maker',
          'Country decision maker'
        ],
        'countryPcs': [
          'Country pcs',
          'Country pcs'
        ],
        'createDocument': [
          'Create Document',
          'Create Document'
        ],
        'createKueb': [
          'Create Küb',
          'Create Küb'
        ],
        'createNewCustomer': [
          'Create new customer',
          'Create new customer'
        ],
        'createNewContactPerson': [
          'Create new contact person',
          'Create new contact person'
        ],
        'createOrderOverview': [
          'Create Order Overview',
          'Create Order Overview'
        ],
        'createOrderRequest': [
          'Create Order Request',
          'Create Order Request'
        ],
        'createPoa': [
          'Create PO-A',
          'Create PO-A'
        ],
        'createSaLight': [
          'Create SA Light',
          'Create SA Light'
        ],
        'creationDateFrom': [
          'Creation Date from',
          'Creation Date from'
        ],
        'creationDateTo': [
          'Creation Date to',
          'Creation Date to'
        ],
        'customer': [
          'Customer',
          'Customer'
        ],
        'customerCity': [
          'Customer city',
          'Customer city'
        ],
        'customerCountry': [
          'Customer country',
          'Customer country'
        ],
        'customerName': [
          'Customer name',
          'Customer name'
        ],
        'dateOfAuthorizedFolder': [
          'Date of authorized folder',
          'Date of authorized folder'
        ],
        'decision': [
          'Decision',
          'Decision'
        ],
        'decisionDateFrom': [
          'Decision Date from',
          'Decision Date from'
        ],
        'decisionDateTo': [
          'Decision Date to',
          'Decision Date to'
        ],
        'delete': [
          'Delete',
          'Delete'
        ],
        'deleteCostPerYear': [
          'Delete Cost Per Year',
          'Delete Cost Per Year'
        ],
        'deleteCostPerYearMessage': [
          'Are you sure you want to delete the cost? Actual values from SAP will be lost.',
          'Are you sure you want to delete the cost? Actual values from SAP will be lost.'
        ],
        'deleteLayoutSettingsTemplateMessage': [
          'Are you sure you want to delete this settings template?',
          'Are you sure you want to delete this settings template?'
        ],
        'department': [
          'Department',
          'Department'
        ],
        'descriptionExample': [
          'Description (e.g. \'Training;Bonus;Spares\')',
          'Description (e.g. \'Training;Bonus;Spares\')'
        ],
        'deviation': [
          'Deviation',
          'Deviation'
        ],
        'discard': [
          'Discard',
          'Discard'
        ],
        'discuss': [
          'Discuss',
          'Discuss'
        ],
        'documentCreationStarted': [
          'PEGA document creation started',
          'PEGA document creation started'
        ],
        'download': [
          'Download',
          'Download'
        ],
        'drives': [
          'Drives',
          'Drives'
        ],
        'endUserAgreementSigned': [
          'End-user agreement signed',
          'End-user agreement signed'
        ],
        'endOfContract': [
          'End of contract',
          'End of contract'
        ],
        'endOfWarrantyDate': [
          'End of warranty date',
          'End of warranty date'
        ],
        'epowertrainSales': [
          'e-Powertrain Sales',
          'e-Powertrain Sales'
        ],
        'epowertrainVolume': [
          'e-Powertrain Volume',
          'e-Powertrain Volume'
        ],
        'exitProject': [
          'Exit project',
          'Exit project'
        ],
        'exitProjectMessage': [
          'Are you sure you want to exit the opened project? Unsaved changes will be lost.',
          'Are you sure you want to exit the opened project? Unsaved changes will be lost.'
          ],
        'fileTooBig': [
          'File size must not exceed 20Mb',
          'File size must not exceed 20Mb'
        ],
        'fileTypeNotPermitted': [
          'File type not permitted',
          'File type not permitted'
        ],
        'fiscalYearClosing': [
            'Fiscal year closing',
            'Fiscal year closing'
          ],
        'fiscalYearClosed': [
            'The fiscal year is closed',
            'The fiscal year is closed'
        ],
        'fiscalYearClosingMessage': [
            'Are you sure you want to start the fiscal year closing preparation? Users will not be able to modify projects and will be notified in an email.',
            'Are you sure you want to start the fiscal year closing preparation? Users will not be able to modify projects and will be notified in an email.'
        ],
        'fiscalYearCloseMessage': [
            'Are you sure you want to start the fiscal year closing preparation? Data changes for this fiscal year will be disabled and users will recieve a notification email.',
            'Are you sure you want to start the fiscal year closing preparation? Data changes for this fiscal year will be disabled and users will recieve a notification email.'
        ],
        'fiscalYears': [
            'Fiscal Years',
            'Fiscal Years'
        ],
        'fy': [
          'FY',
          'FY'
        ],
        'fyAssigned': [
          'FY-Assigned',
          'FY-Assigned'
        ],
        'fyCharged': [
          'FY-Charged',
          'FY-Charged'
        ],
        'fyPlan': [
          'FY-Plan',
          'FY-Plan'
        ],
        'firstName': [
          'First name',
          'First name'
        ],
        'firstTimeCreated': [
          'First time created',
          'First time created'
        ],
        'group': [
          'Group',
          'Group'
        ],
        'ifaNo': [
          'IFA No.'
        ],
        'importKafkaData': [
            'Import Kafka data',    
            'Import Kafka data'    
        ],
        'importKafkaDataMessage': [
            'Are you sure you want to start a new Kafka import? The import will impact the orders and the Actual values in the database.',    
            'Are you sure you want to start a new Kafka import? The import will impact the orders and the Actual values in the database.'
        ],
        'inTheReport': [
          'In the report',
          'In the report'
        ],
        'invalidValueForFields': [
          'Invalid value for fields',
          'Invalid value for fields'
        ],
        'kpriceStation': [
          'K-Price Station',
          'K-Price Station'
        ],
        'kuebWarningMessage': [
          'The printout might not be correct due to more than 6 measures. Please edit manually',
          'The printout might not be correct due to more than 6 measures. Please edit manually'
        ],
        'lastName': [
          'Last name',
          'Last name'
        ],
        'loading': [
          'Loading...',
          'Loading...'
        ],
        'localProjectManagement': [
          'Local project management',
          'Local project management'
        ],
        'locked': [
          'Locked',
          'Locked'
        ],
        'lockedDueToFiscalYearClosing': [
              'Locked due to fiscal year closing',
              'Locked due to fiscal year closing'
          ],
        'lockedProjects': [
          'Locked Projects',
          'Locked Projects'
        ],
        'lockedSince': [
          'Locked Since',
          'Locked Since'
        ],
        'machineGroup': [
          'Machine group',
          'Machine group'
        ],
        'machineType': [
          'Machine Type',
          'Machine Type'
        ],
        'maingroupExample': [
          'Maingroup (e.g. \'0;1;5\')',
          'Maingroup (e.g. \'0;1;5\')'
        ],
        'missingMandatoryFields': [
          'Missing mandatory fields',
          'Missing mandatory fields'
        ],
        'missingOrderRequestMandatoryFields': [
          'Missing mandatory fields of request',
          'Missing mandatory fields of request'
        ],
        'nc': [
          'NC',
          'NC'
        ],
        'no': [
          'No',
          'No'
        ],
        'notInTheReport': [
          'Not in the report',
          'Not in the report'
        ],
        'nr': [
          'Nr.',
          'Nr.'
        ],
        'oemType': [
          'OEM Type',
          'OEM Type'
        ],
        'offer': [
          'Offer',
          'Offer'
        ],
        'open': [
            'Open',
            'Open'
        ],
        'order': [
          'Order',
          'Order'
        ],
        'orderRequestContact': [
          'Contact',
          'Contact'
        ],
        'orderRequestCountry': [
          'Country',
          'Country'
        ],
        'orderRequestDescription': [
          'Description',
          'Description'
        ],
        'orderRequestMainGroup': [
          'Maingroup',
          'Maingroup'
        ],
        'orderRequestSap': [
          'Sap number of project',
          'Sap number of project'
        ],
        'orderRequestVolume': [
          'Volume',
          'Volume'
        ],
        'orderValue': [
          'Order Value',
          'Order Value'
        ],
        'patacNo': [
          'PATAC No.',
          'PATAC No.'
        ],
        'pega': [
          'PEGA',
          'PEGA'
        ],
        'pegaWorkflow': [
          'PEGA workflow',
          'PEGA workflow'
        ],
        'plc': [
          'PLC',
          'PLC'
        ],
        'poBox': [
          'P. O. box',
          'P. O. box'
        ],
        'poBoxPostalCode': [
          'P. O. box postal code',
          'P. O. box postal code'
        ],
        'postalCode': [
          'Postal code',
          'Postal code'
        ],
        'prepareFiscalYearForClosing': [
            'Prepare fiscal year for closing',
            'Prepare fiscal year for closing'
        ],
        'price': [
          'Price',
          'Price'
        ],
        'productionArea': [
          'Production area',
          'Production area'
        ],
        'project': [
          'Project',
          'Project'
        ],
        'projectCountry': [
          'Project country',
          'Project country'
        ],
        'projectLocation': [
          'Project location',
          'Project location'
        ],
        'projectName': [
          'Project Name',
          'Project Name'
        ],
        'projectReminders': [
          'Project Reminders',
          'Project Reminders'
        ],
        'projectStatus': [
          'Project Status',
          'Project Status'
        ],
        'quantity': [
          'Quantity',
          'Quantity'
        ],
        'remark': [
          'Remark',
          'Remark'
        ],
        'reminder': [
          'Reminder',
          'Reminder'
        ],
        'reportingLayout': [
          'Reporting layout',
          'Reporting layout'
          ],
        'responsible': [
          'Responsible',
          'Responsible'
        ],
        'responsibleHeadquarterCommercial': [
          'Responsible headquarter commercial',
          'Responsible headquarter commercial'
        ],
        'responsibleHeadquarterTechnical': [
          'Responsible headquarter technical',
          'Responsible headquarter technical'
        ],
        'reviewDocument': [
          'Review document',
          'Review document'
        ],
        'sales': [
          'Sales',
          'Sales'
        ],
        'salutation': [
          'Salutation',
          'Salutation'
        ],
        'sapCountry': [
          'SAP Country',
          'SAP Country'
        ],
        'sapCustomer': [
          'SAP Customer',
          'SAP Customer'
        ],
        'sapNo': [
          'SAP No',
          'SAP No'
        ],
        'save': [
          'Save',
          'Save'
        ],
        'saveContact': [
          'Save Contact',
          'Save Contact'
        ],
        'saveCustomer': [
          'Save Customer',
          'Save Customer'
        ],
        'saveProject': [
          'Save project',
          'Save project'
        ],
        'saveSettings': [
          'Save settings',
          'Save settings'
        ],
        'serviceCategoryName': [
          'Servoce category name',
          'Servoce category name'
        ],
        'services': [
          'Services',
          'Services'
        ],
        'softwareSales': [
          'Software Sales',
          'Software Sales'
        ],
        'softwareVolume': [
          'Software Volume',
          'Software Volume'
        ],
        'sop': [
          'SOP',
          'SOP'
        ],
        'startPega': [
          'Start PEGA',
          'Start PEGA'
        ],
        'status': [
          'Status',
          'Status'
        ],
        'street': [
          'Street',
          'Street'
        ],
        'sum': [
          'Sum',
          'Sum'
        ],
        'supportedVolume': [
          'Supported volume',
          'Supported volume'
        ],
        'switchProject': [
          'Switch project',
          'Switch project'
        ],
        'switchProjectMessage': [
          'Are you sure you want to switch to a new project? Unsaved changes will be lost.',
          'Are you sure you want to switch to a new project? Unsaved changes will be lost.'
        ],
        'total': [
          'Total',
          'Total'
        ],
        'totalCosts': [
          'Total Costs',
          'Total Costs'
        ],
        'totalPcs': [
          'Total pcs',
          'Total pcs'
        ],
        'totalPrice': [
          'Total Price',
          'Total Price'
        ],
        'totalSales': [
          'Total Sales',
          'Total Sales'
        ],
        'totalVolume': [
          'Total Volume',
          'Total Volume'
        ],
        'totalQuantity': [
          'Total Quantity',
          'Total Quantity'
        ],
        'useValuesFromCostYears': [
          'Use values from Cost Years',
          'Use values from Cost Years'
        ],
        'unassigned': [
          'Unassigned',
          'Unassigned'
        ],
        'unlock': [
          'Unlock',
          'Unlock'
        ],
        'unlockProject': [
          'Unlock Project',
          'Unlock Project'
        ],
        'unlockProjectMessage': [
          'Insert PPA No. to approve the project',
          'Insert PPA No. to approve the project'
          ],
        'upload': [
          'Upload',
          'Upload'
        ],
        'uploadAttachment': [
          'Upload attachment',
          'Upload attachment'
        ],
        'visualization': [
          'Visualization',
          'Visualization'
        ],
        'volume': [
          'Volume',
          'Volume'
        ],
        'volumeByYears': [
          'Volume By Years',
          'Volume By Years'
        ],
        'volumeExample': [
          'Volume (e.g. \'500,00Euro;2000,00Euro;3000,00Euro\')',
          'Volume (e.g. \'500,00Euro;2000,00Euro;3000,00Euro\')'
        ],
        'volumes': [
          'Volumes',
          'Volumes'
        ],
        'volumeOverview': [
          'Volume Overview',
          'Volume Overview'
        ],
        'yes': [
          'Yes',
          'Yes'
        ]
      },
    'snackbarMessages': {
        'cannotDeleteProjectAttachments': [
            'Cannot delete project attachments!',
            'Cannot delete project attachments!'
        ],
        'cannotSaveProjectAttachment': [
            'Cannot save project attachment!',
            'Cannot save project attachment!'
        ],
        'cannotDownloadProjectAttachment': [
            'Cannot download project attachment!',
            'Cannot download project attachment!'
        ],
        'cannotGetCurrentFiscalYear': [
            'Cannot get current fiscal year',
            'Cannot get current fiscal year'
        ],
        'cannotGetFiscalYears': [
            'Cannot get fiscal year list',
            'Cannot get fiscal year list'
        ],
        'cannotSaveFiscalYear': [
            'Cannot save fiscal year',
            'Cannot save fiscal year'
        ],
        'cannotGenerateKueb': [
          'Cannot generate Küb',
          'Cannot generate Küb'
        ],
        'cannotCreateLayoutSettingsTemplate': [
          'Cannot create layout settings template',
          'Cannot create layout settings template'
        ],
        'cannotDeleteLayoutSettingsTemplate': [
          'Cannot delete layout settings template',
          'Cannot delete layout settings template'
        ],
        'cannotGenerateOrderOverview': [
          'Cannot generate Order Overview',
          'Cannot generate Order Overview'
        ],
        'cannotGenerateOrderRequest': [
          'Cannot generate Order Request',
          'Cannot generate Order Request'
        ],
        'cannotGenerateReport': [
          'Cannot generate report',
          'Cannot generate report'
        ],
        'cannotGeneratePega': [
            'Cannot generate PEGA',
            'Cannot generate PEGA'
        ],
        'cannotGeneratePoa': [
          'Cannot generate PO-A',
          'Cannot generate PO-A'
        ],
        'cannotGenerateSaLight': [
          'Cannot generate SA Light',
          'Cannot generate SA Light'
        ],
        'cannotLoadAvailableValues': [
          'Cannot load available values!',
          'Cannot load available values!'
        ],
        'cannotLoadLayouts': [
          'Cannot load layouts!',
          'Cannot load layouts!'
        ],
        'cannotLoadProjectAttachments': [
            'Cannot load project attachments!',
            'Cannot load project attachments!'
        ],
        'cannotLoadProject': [
          'Cannot load project!',
          'Cannot load project!'
        ],
        'cannotLoadProjects': [
          'Cannot load projects!',
          'Cannot load projects!'
        ],
        'cannotStartThePegaWorkflow': [
          'Cannot start the PEGA workflow!',
          'Cannot start the PEGA workflow!'
        ],
        'cannotSaveContact': [
          'Cannot save contact!',
          'Cannot save contact!'
        ],
        'cannotSaveCustomer': [
          'Cannot save customer!',
          'Cannot save customer!'
        ],
        'cannotUpdateLayoutSettingsTemplate': [
          'Cannot delete layout settings template',
          'Cannot delete layout settings template'
        ],
        'createProjectFirst': [
          'You must create the project first!',
          'You must create the project first!'
        ],
        'failedToApproveTheProject': [
          'Failed to approve the project',
          'Failed to approve the project'
        ],
        'failedToSaveTheProject': [
          'Failed to save the project!',
          'Failed to save the project!'
        ],
        'fiscalYearSaved': [
            'Fiscal year saved',
            'Fiscal year saved'
        ],
        'kafkaDataImported': [
            'The Kafka data import was successful. Number of entries imported:',
            'The Kafka data import was successful. Number of entries imported:'
        ],
        'kafkaDataImportFailed': [
            'There was an error during the Kafka import',
            'There was an error during the Kafka import'
        ],
        'kafkaDataImportFailedNoData': [
            'The Kafka importer received no entries',
            'The Kafka importer received no entries'
        ],
        'layoutSettingsTemplateCreatedSuccessfully': [
          'Layout settings template created successfully!',
          'Layout settings template created successfully!'
        ],
        'layoutSettingsTemplateDeletedSuccessfully': [
          'Layout settings template deleted successfully!',
          'Layout settings template deleted successfully!'
        ],
        'layoutSettingsTemplateUpdatedSuccessfully': [
          'Layout settings template updated successfully!',
          'Layout settings template updated successfully!'
        ],
        'missingCountry': [
          'Missing country!',
          'Missing country!'
        ],
        'missingFiscalYear': [
          'Missing fiscal year!',
          'Missing fiscal year!'
        ],
        'noCostsInRow': [
          'There are no costs provided in the row',
          'There are no costs provided in the row'
        ],
        'pegaWorkflowStartedSuccessfully': [
          'PEGA workflow started successfully!',
          'PEGA workflow started successfully!'
        ],
        'projectApprovedSuccessfully': [
          'Project approved successfully',
          'Project approved successfully'
        ],
        'projectSavedSuccessfully': [
          'Project saved successfully!',
          'Project saved successfully!'
        ]
      }
});
