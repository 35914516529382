import { useDispatch } from 'react-redux';
import { Api, apiCall, clearIncompleteDatesFromReportRequest, fileDownload, openLinkInNewTab } from '../../services';
import { PegaWorkflowType } from '../../enums';
import { DocumentGenerationData, OrderRequest, ReportRequest } from '../../models';
import { TRANSLATIONS } from '../../constants';
import { useHook } from '../Hooks';
import { addSnackbar, setPegaLoading, setProjectLoading, setReportPageLoading } from '../../store';
import { SnackbarType } from '../../components/common/Snackbar';
import { useAppSelector, useTranslate } from '../common';

export const useDocumentGeneration = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const project = useAppSelector(state => state.project.project);
    const projectChanged = useAppSelector(state => state.project.projectChanged);
    const { onProjectUpdate } = useHook(x => x.projectSave)();

    const handleGenerateOrderOverview = async (orderOverview: DocumentGenerationData) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateOrderOverview(orderOverview),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateOrderOverview), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGenerateOrderRequest = async (orderRequest: OrderRequest) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateOrderRequest(orderRequest),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateOrderRequest), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGeneratePega = async (createWorkflow?: boolean) => {
        let hasError = false;

        dispatch(setPegaLoading(true));

        if (project?.id) {
            await fileDownload(
                Api.generatePega(project?.id, createWorkflow),
                async () => {
                    hasError = true;
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGeneratePega), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.pdf`
            );
        } else {
            hasError = true;
            dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGeneratePega), type: SnackbarType.Error }));
        }

        if (!hasError) {
            if (createWorkflow && project) {
                const pegaWorkflowSuccess = await handleOpenPegaWorkflow(project.id, PegaWorkflowType.Pega);

                pegaWorkflowSuccess && await onProjectUpdate({ ...project, isLocked: true, lockedFrom: new Date().toISOString() });
            }
        }

        dispatch(setPegaLoading(false));

        return !hasError;
    };

    const handleGenerateSaLight = async (saLightData: DocumentGenerationData) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateSaLight(saLightData),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateSaLight), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGenerateKueb = async (kuebData: DocumentGenerationData) => {
        let saveSuccess = false;
        
        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateKueb(kuebData),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateKueb), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.xlsx`
            );

            project?.id && await handleOpenPegaWorkflow(project.id, PegaWorkflowType.Kueb);
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGeneratePoa = async (poaData: DocumentGenerationData) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generatePoa(poaData),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGeneratePoa), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );

            project?.id && await handleOpenPegaWorkflow(project.id, PegaWorkflowType.Poa);
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleOpenPegaWorkflow = async (projectId: string, type: PegaWorkflowType) => {
        let success = true;

        await apiCall(
            Api.getPegaWorkflowData(projectId, type),
            async x => {
                openLinkInNewTab(x.data.link);
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.pegaWorkflowStartedSuccessfully), type: SnackbarType.Success }));
            },
            async () => {
                success = false;
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotStartThePegaWorkflow), type: SnackbarType.Error }));
            }
        );

        return success;
    };

    const handleGenerateReport = async (reportRequest: ReportRequest) => {
        dispatch(setReportPageLoading(true));

        await fileDownload(
            Api.generateReport(clearIncompleteDatesFromReportRequest(reportRequest)),
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateReport), type: SnackbarType.Error }));
            },
            `${reportRequest.layout?.name}.xlsx`
        );

        dispatch(setReportPageLoading(false));
    };

    return {
        onGenerateOrderOverview: handleGenerateOrderOverview,
        onGenerateOrderRequest: handleGenerateOrderRequest,
        onGeneratePega: handleGeneratePega,
        onGenerateSaLight: handleGenerateSaLight,
        onGenerateKueb: handleGenerateKueb,
        onGeneratePoa: handleGeneratePoa,
        onGenerateReport: handleGenerateReport
    };
};
