import React, { MutableRefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { findNode, getEveryValue } from '../../services';
import { useRoleGroup } from '../../hooks/application';
import { useAppSelector, useResized, useTranslate } from '../../hooks/common';
import { ProjectStatus } from '../../enums';
import { CUSTOMER, EMPTY_PROJECT, START_PEGA_ROLES, UNLOCK_ROLES, PROJECT_NAME, PROJECT_STATUSES, TECHNICAL_RESPONSIBLE, CORPORATION_GROUP, CLASS, PROJECT_COUNTRY, COST_YEARS_COUNTRY, COST_YEARS_FY, VOLUMES_FY, VOLUME_OVERVIEW_MACHINE_TYPE, TRANSLATIONS, CHANGE_PROJECT_STATUS_ARBITRARY_ROLES } from '../../constants';
import Button, { ButtonType } from '../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon, { IconType } from '../common/Icon';
import Loader from '../common/Loader';
import Modal from '../common/Modal';
import Select from '../common/Select';
import Title from '../common/Title';
import Tooltip from '../common/Tooltip';
import TextInput from '../common/TextInput';
import { Project, History } from '../../models';
import { setProject } from '../../store';
import { useHook } from '../../hooks';

interface ProjectHeaderProps {
    projectChangeHandled: MutableRefObject<boolean>;
    onGeneratePega: (createWorkflow?: boolean) => Promise<boolean>;
    onProjectApprove: (approveData: Partial<History>) => Promise<boolean>;
    onProjectCreate: (setIdInUrl?: boolean) => Promise<boolean>;
    onProjectSaveInBackground: (project: Project) => Promise<boolean>;
    onProjectUpdate: (project?: Project) => Promise<boolean>;
}

interface EmptyMandatoryFieldWithInfo {
    field: string;
    info: string;
}

const ProjectHeader = ({ onProjectApprove, onProjectCreate, onProjectSaveInBackground, onProjectUpdate, onGeneratePega, projectChangeHandled }: ProjectHeaderProps) => {
    const canUnlock = useRoleGroup(UNLOCK_ROLES);
    const canStartPega = useRoleGroup(START_PEGA_ROLES);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { closingFiscalYearRestriction } = useHook(x => x.fiscalYears)();
    const availableValues = useAppSelector(state => state.project.availableValues);
    const translate = useTranslate();
    const pegaLoading = useAppSelector(state => state.project.pegaLoading);
    const project = useAppSelector(state => state.project.project);
    const projectChanged = useAppSelector(state => state.project.projectChanged);
    const projectLoading = useAppSelector(state => state.project.projectLoading);
    const canChangeProjectStatusArbitrary = useRoleGroup(CHANGE_PROJECT_STATUS_ARBITRARY_ROLES) && !project?.isLocked;
    const initialStatus = useAppSelector(state => canChangeProjectStatusArbitrary ? ProjectStatus.Intern : state.project.initialStatus);
    const mobileView = useResized(x => x <= 530);
    const [ppaNumber, setPpaNumber] = useState('');
    const [projectToSave, setProjectToSave] = useState<Project | undefined>(undefined);
    const [showExitProjectModal, setShowExitProjectModal] = useState(false);
    const [showPegaModal, setShowPegaModal] = useState(false);
    const [showSaveInBackgroundModal, setShowSaveInBackgroundModal] = useState(false);
    const [showUnlockProjectModal, setShowUnlockProjectModal] = useState(false);

    const checkCostYearsCountriesFields = () => {
        const costsWithEmptyCountry = project?.countryCostsPerYears?.filter(x => x.countryId === 0);

        return costsWithEmptyCountry?.length ?
            [...new Map(
                costsWithEmptyCountry.map<EmptyMandatoryFieldWithInfo>(x => (
                        {
                            field: COST_YEARS_COUNTRY,
                            info: `(${translate(TRANSLATIONS.main.costYearsCountry)} ${availableValues.fiscalYears.find(y => y.id === x.fiscalYearId)?.name ?? ''})`
                        })
                    ).map(item => [item.info, item])
                ).values() ?? [] 
            ]
            : [];
    };
    const checkCostYearsFields = () => project?.costsPerYears?.some(x => x.fiscalYearId === 0)
        ? [{ field: COST_YEARS_FY, info: `(${translate(TRANSLATIONS.main.costYears)})` }]
        : [];
    const checkVolumesFields = () => project?.volumes?.some(x => x.fiscalYearId === 0)
        ? [{ field: VOLUMES_FY, info: `(${translate(TRANSLATIONS.main.volumes)})` }]
        : [];
    const checkVolumeOverviewFields = () => project?.volumeOverviews?.some(x => x.machineSubCategoryId == null)
        ? [{ field: VOLUME_OVERVIEW_MACHINE_TYPE, info: `(${translate(TRANSLATIONS.main.volumeOverview)})` }]
        : [];

    const emptyMandatoryFields = [
        ...project && project.name.length ? [] : [PROJECT_NAME],
        ...project && project.customer?.id ? [] : [CUSTOMER],
        ...project && project.hqTechnicalResponsible?.id ? [] : [TECHNICAL_RESPONSIBLE],
        ...project && project.corporationGroup?.id ? [] : [CORPORATION_GROUP],
        ...project && project.projectCategory != null ? [] : [CLASS],
        ...project && project.country != null ? [] : [PROJECT_COUNTRY],
        ...project && project.volumeOverviews != null ? checkVolumeOverviewFields() : [],
        ...project && project.volumes != null ? checkVolumesFields() : [],
        ...project && project.costsPerYears != null ? checkCostYearsFields() : [],
        ...project && project.countryCostsPerYears != null ? checkCostYearsCountriesFields() : []
    ];

    const tooltipText = `<div style='text-align: center'>${translate(TRANSLATIONS.main.missingMandatoryFields)}: ${emptyMandatoryFields
        .map((x, i) => {
            type ObjectKey = keyof typeof TRANSLATIONS.main;
            const key = (typeof x === 'object' ? x.field : x) as ObjectKey;

            return `${typeof x === 'object'
                ? `<br />${translate(TRANSLATIONS.main[key])} ${x.info}`
                : `<br />${translate(TRANSLATIONS.main[key])}`}`;
        })
    }</div>`;

    useEffect(() => {
        if (project && projectChanged && id !== 'new' && id !== project.id && !projectChangeHandled.current) {
            setProjectToSave(project);
            setShowSaveInBackgroundModal(true);
            projectChangeHandled.current = true;
        }
        else {
            projectChangeHandled.current = false;
        }
    }, [id, project, projectChanged]);

    const onSaveInBackground = async () => {
        if (projectToSave) {
            const success = await onProjectSaveInBackground(projectToSave);

            if (success) {
                onCloseSaveInBackgroundModal();
            }
        }
    };

    const onCloseSaveInBackgroundModal = () => {
        setShowSaveInBackgroundModal(false);

        !id && dispatch(setProject(undefined));
    };

    const onExit = () => {
        setShowExitProjectModal(false);
        dispatch(setProject(undefined));
        navigate('/');
        projectChangeHandled.current = true;
    };
    
    const onSave = () => {
        project?.id ? onProjectUpdate() : onProjectCreate(true);
    };

    const onSaveAndExit = async () => {
        setShowExitProjectModal(false);

        const success = await (project?.id ? onProjectUpdate() : onProjectCreate());

        success && onExit();
    };

    const onUnlock = async () => {
        if(project?.id) {
            const updateSuccess = await onProjectUpdate({ ...project, isLocked: false });
            updateSuccess && setShowUnlockProjectModal(false);
        }
    };

    const onApprove = async () => {
        if(project?.id) {
            const approveSuccess = await onProjectApprove({ projectId: project.id, ppaNumber: ppaNumber });
            approveSuccess && onUnlock();
        }
    };

    const onStartPega = async () => {
        const success = await onGeneratePega(true);

        success && setShowPegaModal(false);
    };

    const onReviewDocument = () => {
        onGeneratePega();
    };

    const renderSaveInBackgroundModal = () => {
        return (
            <Modal open={showSaveInBackgroundModal} movable>
                <Title>{translate(TRANSLATIONS.main.saveProject)}</Title>
                <div style={{ margin: '10px 0' }}>{translate(TRANSLATIONS.main.backgroundSaveMessage)}</div>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={onCloseSaveInBackgroundModal}>
                        {translate(TRANSLATIONS.main.no)}
                    </Button>
                    <Button type={ButtonType.Primary} onClick={onSaveInBackground}>
                        {translate(TRANSLATIONS.main.yes)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderStartPegaModal = () => {
        return (
            <Modal open={showPegaModal} movable>
                <Loader loading={pegaLoading} margin={10}>
                    <Title>{translate(TRANSLATIONS.main.pegaWorkflow)}</Title>
                    <div style={{ margin: '10px 0' }}>{translate(TRANSLATIONS.main.documentCreationStarted)}</div>
                    <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                        <Button type={ButtonType.Tertiary} onClick={() => setShowPegaModal(false)}>
                            {translate(TRANSLATIONS.main.cancel)}
                        </Button>
                        <Button type={ButtonType.Secondary} onClick={() => onReviewDocument()}>
                            {translate(TRANSLATIONS.main.reviewDocument)}
                        </Button>
                        <Button type={ButtonType.Primary} disabled={closingFiscalYearRestriction() || project?.isLocked || projectChanged} onClick={() => onStartPega()}>
                            {translate(TRANSLATIONS.main.startPega)}
                        </Button>
                    </Flex>
                </Loader>
            </Modal>
        );
    };
 
    const renderExitProjectModal = () => {
        return (
            <Modal open={showExitProjectModal} movable>
                <Title>{translate(TRANSLATIONS.main.exitProject)}</Title>
                <div style={{ margin: '10px 0' }}>{translate(TRANSLATIONS.main.exitProjectMessage)}</div>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={() => setShowExitProjectModal(false)}>
                        {translate(TRANSLATIONS.main.cancel)}
                    </Button>
                    <Button type={ButtonType.Secondary} onClick={onExit}>
                        {translate(TRANSLATIONS.main.discard)}
                    </Button>
                    <Tooltip 
                        key={tooltipText}
                        id='saveTooltip'
                        delayShow={450}
                        text={tooltipText}
                        place='bottom'
                        hide={!emptyMandatoryFields.length}
                    >
                        <Button type={ButtonType.Primary} onClick={onSaveAndExit} disabled={Boolean(emptyMandatoryFields.length)}>
                            {translate(TRANSLATIONS.main.save)}
                        </Button>
                    </Tooltip>
                </Flex>
            </Modal>
        );
    };

    const renderUnlockProjectModal = () => {
        return (
            <Modal open={showUnlockProjectModal} movable>
                <Title>{translate(TRANSLATIONS.main.unlockProject)}</Title>
                <div style={{ margin: '10px 0' }}>{translate(TRANSLATIONS.main.unlockProjectMessage)}</div>
                <div style={{ width: 200, marginTop: 20 }}>
                    <TextInput label='PPA No.' name='PPANo' value={ppaNumber ?? ''} onChange={x => setPpaNumber(x)} />
                </div>               
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={() => setShowUnlockProjectModal(false)}>
                        {translate(TRANSLATIONS.main.cancel)}
                    </Button>
                    <Button type={ButtonType.Secondary} onClick={onUnlock}>
                        {translate(TRANSLATIONS.main.unlock)}
                    </Button>
                    <Button type={ButtonType.Primary} onClick={onApprove} disabled={!ppaNumber.length}>
                        {translate(TRANSLATIONS.main.approve)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderButtons = () => {        
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ flexShrink: 0 }}>
                {project?.isLocked && canUnlock && 
                    <Button type={ButtonType.Primary} onClick={() => setShowUnlockProjectModal(true)}>
                        {translate(TRANSLATIONS.main.unlockProject)}
                    </Button>
                }
                {canStartPega &&
                    <Button type={ButtonType.Primary} disabled={projectLoading || !project?.id || projectChanged} onClick={() => setShowPegaModal(true)}>
                        {translate(TRANSLATIONS.main.pega)}
                    </Button>
                }
                <Select
                    label='Project Status'
                    value={project?.status ?? ProjectStatus.Intern}
                    values={getEveryValue(findNode(PROJECT_STATUSES, x => x === initialStatus))}
                    filled={x => x != null}
                    mapToString={x => ProjectStatus[x]}
                    onSelect={x => dispatch(setProject({ ...project ?? EMPTY_PROJECT, status: x }))}
                    disabled={closingFiscalYearRestriction() || projectLoading}
                />
                <Tooltip 
                    key={tooltipText}
                    id='saveTooltip'
                    delayShow={450}
                    text={tooltipText}
                    place='bottom'
                    hide={!emptyMandatoryFields.length}
                >
                    <Button type={ButtonType.CallToAction} disabled={projectLoading || emptyMandatoryFields.length > 0 || !projectChanged} onClick={onSave}>
                        <Icon type={IconType.Save} />
                    </Button>
                </Tooltip>
                <Button type={ButtonType.Secondary} onClick={() => projectChanged ? setShowExitProjectModal(true) : onExit()}>
                    <Icon type={IconType.Logout} />
                </Button>
            </Flex>
        );
    };

    return (
        <div className={`project-header ${project ? '' : 'hide'}`}>
            <Flex direction={FlexDirection.Row} justification={mobileView ? FlexJustification.FlexEnd : FlexJustification.SpaceBetween} gap={10}>
                {!mobileView &&
                    <Flex direction={FlexDirection.Column} justification={FlexJustification.FlexStart} style={{ minWidth: 0 }}>
                        <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart} gap={8}>
                            <div className='project-title'>{project?.name}</div>
                            {project?.isLocked && <div className='project-locked-tag'>{translate(TRANSLATIONS.main.locked)}</div>}
                            {closingFiscalYearRestriction() && <div className='project-locked-tag'>{translate(TRANSLATIONS.main.lockedDueToFiscalYearClosing)}</div>}
                        </Flex>
                        <div className='project-description'>{[project?.customer?.name, project?.customer?.city, project?.sapNr].filter(x => x).join(', ')}</div>
                    </Flex>
                }
                {renderButtons()}
            </Flex>
            {renderExitProjectModal()}
            {renderUnlockProjectModal()}
            {renderStartPegaModal()}
            {renderSaveInBackgroundModal()}
        </div>
    );
};

export default ProjectHeader;
